import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["packageField", "directPurchaseReminder"]
  static values = { packagePermittingDirectPurchase: String }

  connect() {
    this.choosePackageFromUrlParam()
  }

  choosePackageFromUrlParam() {
    const urlParams = new URLSearchParams(window.location.search)
    const packageParam = urlParams.get("package")

    if (packageParam) {
      this.choosePackage(packageParam)
    }
  }

  choosePackage(packageName) {
    this.packageFieldTarget.value = packageName
  }

  showDirectPurchaseIfPackagePermits(event) {
    if (this.packagePermittingDirectPurchaseValue === this.packageFieldTarget.value) {
      this.directPurchaseReminderTarget.show()
    }
  }
}
